import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/careers/Footer_background.svg";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import bannerBgImageSrc from "../images/v2/xero/banner-bg-img.png";
import bgRootSrc from "../images/v2/xero/bg-root-img.png";
import XeroBanner from "../images/v2/xero/Pc+Xero@2x.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import xeroFeatureOne from "../images/v2/xero/xero-feature-one.svg";
import xeroFeatureTwo from "../images/v2/xero/xero-feature-two.svg";
import xeroFeatureThree from "../images/v2/xero/xero-feature-three.svg";
import xeroFeatureFour from "../images/v2/xero/xero-feature-four.svg";
import gridBgImgSrc from "../images/v2/xero/grid-bg-img.svg";
import bannerBottomImgSrc from "../images/v2/xero/banner-bottom-img@2x.png";

const xero = `Empower your team to generate and manage quotations efficiently through the powerful integration.
Monitor each stage of your quotations. Be aware if your quotations have been accepted, declined, or invoiced.
Access quotations either in Xero or in the Pepper Cloud CRM system with the powerful 2-way sync feature of the integration.
Save valuable time and efforts of your team by using readily available email templates and quotation formats.
Escape from the tedious manual data entry process and auto-populate the details from the accounting software.
Sync data and avoid switching between tabs with the help of this CRM and accounting software integration.`.split(`
`);

const productBenefits = [
	{
		title: "Access real-time view of cash flow",
		text:
			"Log in anytime from anywhere to get a real-time view of your cash flow. It’s small business accounting software that’s simple, smart and occasionally magical.",
		alt: "Real-time accounting",
		img: xeroFeatureOne,
	},
	{
		title: "Run your business on the go",
		text: "Reconcile, send and create quotations, invoices or expense claims through the mobile app of Xero from anywhere.",
		alt: "Xero accounting",
		img: xeroFeatureTwo,
	},
	{
		title: "Experience efficient accounting",
		text:
			"Send online quotations to your customers and get the quotation status in real-time.",
		alt: "sales quotations",
		img: xeroFeatureThree,
	},
	{
		title: "Reconcile in seconds",
		text:
			"Xero imports and categorises your latest bank transactions. Just click ok to reconcile.",
		alt: "quoatations reconcilation",
		img: xeroFeatureFour,
	},
];

const useStyles = makeStyles((theme) => ({
	bgRoot: {
		background: `url(${bgRootSrc}) no-repeat`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	bannerBgImage: {
		background: `url(${bannerBgImageSrc}) no-repeat`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	img: {
		maxWidth: "100%",
		height: "auto",
	},
	btn: {
		// minWidth: 307,
		// minHeight: 52,
		// fontWeight: 600,
		// fontSize: 20,
	},
	requestDemoRoot: {
		background: `url(${requestDemoBGImg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	testimonialCustomerImg: {
		maxWidth: "90%",
		width: "290px",
		borderRadius: "10px",
		boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
		marginTop: "2.65rem",
		marginLeft: "3rem",
	},
	testimonialBg: {
		background: `url(${testimonialBgImg}) no-repeat`,
		backgroundSize: "195px 223px",
	},
	featureItem: {
		borderLeft: "1px solid",
		borderRadius: theme.shape.borderRadius * 2.5,
		borderColor: "#e2f0ff",
		padding: theme.spacing(4),
		[theme.breakpoints.down("sm")]: {
			borderLeft: "unset",
		},
	},
	listItemText: {
		"& *": {
			fontSize: 20,
			color: "#2e3f4f",
			[theme.breakpoints.down("sm")]: {
				fontSize: 16,
			},
		},
	},
	listItemIcon: {
		color: "#fff",
		fontSize: 36,
		backgroundColor: "#08a742",
		borderRadius: "50%",
		padding: "5px",
		marginRight: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			fontSize: 28,
			marginRight: theme.spacing(1.5),
		},
	},
	cycleProcessImg: {
		maxWidth: "530px",
		height: "auto",
	},
	xeroConnectedAppImg: {
		maxWidth: "280px",
		height: "auto",
	},
	bannerBottomImg: {
		marginTop: "-50px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "-25px",
		},
	},
	gridImg: {
		[theme.breakpoints.up("md")]: {
			background: `url(${gridBgImgSrc}) no-repeat`,
			backgroundPosition: "center 100px",
		},
	},
	bannerBottomImgClass: {
		maxWidth: "980px",
		height: "auto",
		width: "100%",
	},
	bannerParaText: {
		[theme.breakpoints.up("md")]: {
			paddingRight: "120px",
		},
	},
	bannerContent: {
		[theme.breakpoints.down("md")]: {
			textAlign: "center",
		},
	},
}));

const client = {
	image: (
		<StaticImage
			alt="Pepper Cloud + Mustard Seed System Corporation"
			layout="constrained"
			src="../images/v2/testimonials-images/judith-adao-peppercloud-customer.png"
		/>
	),
	name: "Judith M. Adao",
	position: "General Manager",
	company: "Mustard Seed Systems Corporation, Philippines",
	contentSmall: `“Pepper Cloud CRM is a must for every growing sales organization. Very simple, yet it gives all the information needed to manage the sales leads.”`,
	content: (
		<React.Fragment>
			Pepper Cloud is a simple and easy to use CRM tool and it gives all the information needed to manage the sales
			leads. The CRM also helps us to run data driven marketing campaigns.
			<br />
			<br />
			{/* eslint-disable-next-line react/no-unescaped-entities */}
			Pepper Cloud's customer service and local support are incredible and helped us at every stage of the
			implementation. This CRM is a must for every growing sales organization.
		</React.Fragment>
	),
};

const Webform = () => {
	const width = useWidth();
	const classes = useStyles();
	return (
		<React.Fragment>
			<SEO
				canonical="/xero"
				description="Try the best sales CRM system with the world’s leading accounting software: Xero integration. Get one complete view of your business, from capturing leads to invoicing & payments."
				keywords="xero crm integrations,crm and accounting software integration,crm software integration,Xero integration,best crm for xero"
				ogData={{
					"og:image": ["/meta/og/1X1/web-form.png", "/meta/og/1024X512/web-form.png", "/meta/og/1200X630/web-form.png"],
				}}
				pathname="/xero"
				title="CRM Xero integration | Pepper Cloud"
			/>
			<Box className={classes.bannerBgImage}>
				<Container>
					<Box
						alignItems="center"
						component={Grid}
						container
						justify="center"
						className={classes.bannerContent}
						py={6}
					>
						<Grid item md={6} sm={12}>
							<HeaderTypography component="h1" mb={4} mt={{ sm: 6, xs: 5 }[width] || 12}>
								Pepper Cloud + Xero integration
						</HeaderTypography>
							<ParagraphTypography className={classes.bannerParaText} mb={6}>
								Generate and track quotations with Xero integrated sales CRM. Reduce manual data entry errors, use preset email templates, and manage the entire sales process with higher efficiency.
						</ParagraphTypography>
							<Box mb={{ md: 14 }} />
						</Grid>
						<Grid item md={6} sm={12}>
							<Box textAlign="center" p={3}>
								<img alt="Pepper Cloud + Xero integration" className={classes.img} src={XeroBanner} />
							</Box>
						</Grid>
					</Box>
				</Container>
			</Box>
			<Box className={classes.bannerBottomImg}>
				<Box textAlign="center">
					<img alt="Xero Integration" className={classes.bannerBottomImgClass} src={bannerBottomImgSrc} />

					{/* <StaticImage
						alt="CRM sales cycle"
						placeholder="blurred"
						src="../images/v2/xero/banner-bottom-img@2x.png"
					/> */}
				</Box>
			</Box>
			<Box mx={4} my={{ sm: 0, xs: 0 }[width] || 6}>
				<Container>
					<Grid alignItems="center" container justify="center" spacing={8}>
						<Grid className={classes.header} item md={4} sm={12}>
							<Box textAlign="center">
								<HeaderTypography component="h2" mb={4} mt={2}>
									About XERO
					</HeaderTypography>
								<ParagraphTypography mb={2}>
									Xero is world-leading online accounting software built for small businesses.
					</ParagraphTypography>
								<Box my={3} textAlign="center">
									<StaticImage
										alt="Xero CRM integration"
										className={classes.xeroConnectedAppImg}
										placeholder="blurred"
										src="../images/v2/xero/xero-connected-app-logo@2x.png"
									/>
								</Box>
								<Button
									className={classes.bookNowButton}
									color="secondary"
									component={Link}
									size="large"
									to="https://www.xero.com/us/"
									variant="contained"
									target="_blank"
								>
									Try Xero for free
									</Button>
							</Box>
						</Grid>
						<Grid item md={8} sm={12} xs={12}>
							<Grid container className={classes.gridImg} justify="center">
								{productBenefits.map((each) => (
									<Grid item key={each.text} md={6} sm={6}>
										<Box textAlign="center" m={4}>
											<Box alt={each.alt} component="img" height={50} src={each.img} />
											<HeaderTypography fontSize={18} mb={1}>
												{each.title}
											</HeaderTypography>
											<ParagraphTypography fontSize={16} fontWeight={400} m={0}>
												{each.text}
											</ParagraphTypography>
										</Box>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box className={classes.bgRoot}>
				<Box>
					<Box component={Container}>
						<Box display="flex" justifyContent="center">
							<Box pb={3}>
								<Box display="flex" justifyContent="center">
									<Box maxWidth={800} pb={{ sm: 2, xs: 2 }[width] || 4} pt={4} textAlign="center">
										<HeaderTypography
											alignItems="center"
											component="h2"
											// fontSize={48}
											display="flex"
											justifyContent="center"
											lineHeight={1.2}
											mb={3}
										>
											Close deals faster by generating quotations on the fly
									</HeaderTypography>
										<ParagraphTypography component="h3" fontSize={18} lineHeight={1.5} mb={4} >
											Integrate <a href="https://peppercloud.com/"> Singapore’s #1 sales CRM</a> with the world's leading accounting software for effortless selling and efficient sales management.
									</ParagraphTypography>
									</Box>
								</Box>
								<Grid alignItems="center" container justify="center" spacing={2}>
									<Grid item md={7} sm={12} xs={12}>
										<Box pr={{ sm: 1, xs: 1 }[width] || 8}>
											<List>
												{xero.map((each) => (
													<ListItem alignItems="center" key={each}>
														<ListItemIcon>
															<CheckIcon className={classes.listItemIcon} />
														</ListItemIcon>
														<ListItemText className={classes.listItemText}>{each}</ListItemText>
													</ListItem>
												))}
											</List>
											<ParagraphTypography component="h3" fontSize={18} lineHeight={1.5} mb={4} pl={3} >
												To know how this integration can benefit your business, read our guide on <a href="https://blog.peppercloud.com/crm-xero-integration/">Xero and CRM software integration.</a>
											</ParagraphTypography>
										</Box>
									</Grid>
									<Grid item md={5} sm={12} xs={12}>
										<Box textAlign="center">
											<StaticImage
												alt="CRM and accounting software integration"
												className={classes.cycleProcessImg}
												placeholder="blurred"
												src="../images/v2/xero/Connect-image@2x.png"
											/>
										</Box>
									</Grid>
								</Grid>
								<br />
								<br />
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box>
				<Container>
					<Box display="flex" justifyContent="center">
						<Box maxWidth={915} py={{ xs: 4, sm: 6, md: 10 }} textAlign="center">
							<HeaderTypography component="h2" fontSize={40} mb={2} mt={0}>
								Enhance your selling experience with Pepper Cloud CRM and Xero integration
								</HeaderTypography>
							<ParagraphTypography color="text.paragraph" fontSize={18} m={0}>
								Accelerate your sales and accounting with Pepper Cloud CRM and Xero software integration.
								</ParagraphTypography>
							{/* <Box mt={4}>
								<Button
									className={classes.bookNowButton}
									color="secondary"
									component={Link}
									size="large"
									to="https://blog.peppercloud.com/crm-xero-integration/"
									variant="contained"
								>
									Go to marketplace
									</Button>
							</Box> */}
						</Box>
					</Box>
				</Container>
			</Box>
			<Box className={classes.requestDemoRoot} py={12}>
				<Container>
					<HeaderTypography color="common.white" fontWeight={600} fontSize={28} mt={0} textAlign="center">
						Are you ready to scale up your business and power up your sales?
					</HeaderTypography>
					<Box display="flex" justifyContent="center" mt={6}>
						<WhiteButton color="primary" component={Link} size="large" to="/contact" variant="contained">
							{/* eslint-disable-next-line react/no-unescaped-entities */}
							Let's do this!
						</WhiteButton>
					</Box>
				</Container>
			</Box>
		</React.Fragment >
	);
};

export default Webform;
